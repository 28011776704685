import { Injectable } from '@angular/core';
import { FormRule, FormRuleEffect } from '../models/form-rule.model';

@Injectable({ providedIn: 'root' })
export class FormRuleFactory {
  /**
   * Creates a new FormRule.
   * @param condition
   * @param effects
   * @returns
   */
  createRule(condition: string, effects: FormRuleEffect[]) {
    return new FormRule(condition, effects);
  }

  /**
   * Creates a deep copy of the provided FormRules.
   * @param rules
   * @returns
   */
  copyRules(rules: FormRule[]): FormRule[] {
    return (JSON.parse(JSON.stringify(rules)) as Array<any>).map((i) =>
      this.createRule(i.condition, i.effects)
    );
  }
}
