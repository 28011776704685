import { DynamicFormControl } from './dynamic-form-control.model';

export class DynamicFormGroup {
  label?: string;
  name?: string;
  children?: (DynamicFormGroup | DynamicFormControl)[] = [];
  customOptions?: any;

  //Group behavior properties
  layoutDirection?: 'column' | 'row';
  hidden?: boolean = false;

  handlers?: Map<string, Function> = new Map<string, Function>();

  /**
   * Sets the hidden property to true, indicating the group is to be marked as hidden in the DOM.
   */
  hideGroup() {
    this.hidden = true;
  }

  /**
   * Sets the hidden property to true, indicating the group is to be marked as hidden in the DOM.
   */
  showGroup() {
    this.hidden = false;
  }

  private addChild(entity: DynamicFormGroup | DynamicFormControl): void {
    this.children = [...this.children, entity];
  }

  /**
   * Adds a nested DynamicFormGroup.
   * @param group
   */
  addGroup(group: DynamicFormGroup): void {
    this.addChild(group);
  }

  /**
   * Adds a DynamicFormControl to the group.
   * @param control
   */
  addControl(control: DynamicFormControl): void {
    this.addChild(control);
  }

  /**
   * Adds a handler to the group.
   * @param name
   * @param delegate
   */
  addHandler(name?: string, delegate?: Function) {
    this.handlers.set(name, delegate);
  }

  /**
   * Gets all controls local to the group.
   * @retruns A collection of DynamicFormControls.
   */
  getLocalControls(): DynamicFormControl[] {
    return this.children.filter(
      (i) => i instanceof DynamicFormControl
    ) as DynamicFormControl[];
  }

  /**
   * Gets all controls for a group and any nested groups.
   * @returns A collection of DynamicFormControls
   */
  getControls(): DynamicFormControl[] {
    let controls: DynamicFormControl[] = [];

    this.children?.forEach((i) => {
      if (i instanceof DynamicFormGroup) {
        controls = [...controls, ...i.getControls()];
      } else {
        controls.push(i);
      }
    });

    return controls;
  }

  /**
   * Gets all the nested groups.
   * @returns
   */
  getNestedGroups(): DynamicFormGroup[] {
    let groups: DynamicFormGroup[] = [];

    this.children?.forEach((i) => {
      if (i instanceof DynamicFormGroup) {
        groups = [...groups, i, ...i.getNestedGroups()];
      }
    });

    return groups;
  }
}
