export class PaginatedList<T> {
  Items: T[];
  HasNextPage: boolean;
  HasPreviousPage: boolean;
  LowerRange: number;
  PageNumber: number;
  PageSize: number;
  TotalCount: number;
  TotalPages: number;
  UpperRange: number;
  SortDirection: string;

  constructor(list: PaginatedList<T>) {
    this.HasNextPage = list.HasNextPage;
    this.HasPreviousPage = list.HasPreviousPage;
    this.LowerRange = list.LowerRange;
    this.UpperRange = list.UpperRange;
    this.PageNumber = list.PageNumber;
    this.PageSize = list.PageSize;
    this.SortDirection = list.SortDirection;
    this.TotalCount = list.TotalCount;
    this.TotalPages = list.TotalPages;
    this.Items = list.Items;
  }
}
