<content-panel>
  @if (!isSmallScreen && !isDetailOpen) {
    <panel-header heading="Credit Submissions" [ngClass]="{'header-detailopen':isDetailOpen}">
    <button class="headerButton" mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>download</mat-icon>
    </button>
    <button class="headerButton" mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <button class="headerButton" mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>add</mat-icon>
    </button>
  </panel-header>
  } @else if(!isSmallScreen && isDetailOpen) {
    <panel-header heading="Credit Submissions" [ngClass]="{'header-detailopen':isDetailOpen}">
    <div class="hamburger-menu">
      <button class="headerButton" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
        <mat-menu #menu="matMenu" class="open-menu">
          <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
            <mat-icon class="menuIcon">download</mat-icon>
          </button>
          <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
            <mat-icon class="menuIcon">filter_alt</mat-icon>
          </button>
          <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
            <mat-icon class="menuIcon">add</mat-icon>
          </button>
        </mat-menu>
      </div>
    </panel-header>
  } @else {
    <panel-header heading="Credit Submissions" [ngClass]="{'header-detailopen':isDetailOpen}">
      <div class="hamburger-menu">
        <button class="headerButton" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
        </button>
          <mat-menu #menu="matMenu" class="open-menu">
            <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
              <mat-icon class="menuIcon">download</mat-icon>
            </button>
            <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
              <mat-icon class="menuIcon">filter_alt</mat-icon>
            </button>
            <button class="menuButton" mat-icon-button (click)="drawerSvc.openFilter()">
              <mat-icon class="menuIcon">add</mat-icon>
            </button>
          </mat-menu>
        </div>
      </panel-header>
   
  }
    

  <panel-body>
    <div class="container">
      @if(isDetailOpen || isMobile) { @for (element of dataSource.data; track element.Id) {
      <section
        mat-ripple
        class="list-row-container"
        [ngClass]="submission?.Id === element.Id ? 'active' : ''"
        (click)="rowSelected(element.Id)"
      >
        <h2 class="primary-content-container">
          {{ element.FacilityNumber }} - {{ element.SerialNumber }}
        </h2>
        <div class="secondary-content-container">
          <div [style.width.%]="33">
            Version:
            <div class="secondary-data-bold">{{ element.Version }}</div>
          </div>
          <div [style.width.%]="33">
            Status:
            <div class="secondary-data-bold">{{ element.Status }}</div>
          </div>
          <div style="align-items: center; width: 33%">
            Sold On:
            <div style="width: 180px;">
              <div class="secondary-data-bold">{{ element.SoldDate | date }}</div>
              @if(this.isLessThanTargetDate(element.SoldDate)) {
                <mat-icon [matTooltip]="soldToolTip" class="ninety-day-secondary">info</mat-icon>
              }
            </div>
          </div>
        </div>
      </section>
      } } @else {
      <section [ngClass]="{'below-1150-table' : isBelow1150}">
        <table mat-table [dataSource]="dataSource">
          @for (column of tableColumns; track column) {
          <ng-container [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>
              @if(column === 'FacilityNumber') {
              <b>{{ "Facility #" }}</b>
              } @else if(column === 'OrderNumber') {
              <b>{{ "Order #" }}</b>
              } @else if(column === 'SerialNumber') {
              <b>{{ "Serial #" }}</b>
              } @else if(column === 'HomeCenterName') {
              <b>{{ "Home Center" }}</b>
              }@else if(column === 'CustomerName') {
              <b>{{ "Customer" }}</b>
              }@else if(column === 'SubmissionCreateDate') {
              <b>{{ "Submission Create Date" }}</b>
              }@else {
              <b>{{ column | tableColumn }}</b>
              }
            </th>
            <td mat-cell *matCellDef="let element">
              @if(column === 'SoldDate') {
                <div style="width:150px;">
                {{ element[column] | date }}
                @if(this.isLessThanTargetDate(element[column])) {
                  <mat-icon [matTooltip]="soldToolTip" class="ninety-day">info</mat-icon>
                }
                </div>
              } @else if(column === 'CertificateMailed') {
                <div class="column-center">{{ element[column] ? "Yes" : "No" }}</div>
              } @else if(column === 'SubmissionCreateDate') {
                <div class="column-center">{{ element[column] | date }}</div>
              }@else {
              {{ element[column] }}
              }
            </td>
          </ng-container>
          }
          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: tableColumns"
            (click)="rowSelected(row.Id)"
          ></tr>
        </table>
      </section>
      }
    </div>
  </panel-body>
  @if (isDetailOpen || isSmallScreen) {
      <mat-paginator style="padding-bottom: 0.9375em;"
      [pageSize]="paginatorOptions.pageSize"
      [length]="paginatorOptions.pageLength"
      (page)="pageChange.emit($event)"
      >
      </mat-paginator>
  } @else {
      <mat-paginator
      [pageSizeOptions]="paginatorOptions.pageSizeOptions"
      [pageSize]="paginatorOptions.pageSize"
      [length]="paginatorOptions.pageLength"
      [showFirstLastButtons]="paginatorOptions.showFirstLastButtons"
      (page)="pageChange.emit($event)"
      >
      </mat-paginator>
  }
</content-panel>
