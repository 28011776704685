import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Submission } from '../models/submission.model';
import {
  PanelBodyComponent,
  PanelContainerComponent,
  PanelHeaderComponent,
} from 'src/app/shared/content-panels';
import { MatIconModule } from 'src/app/shared/material';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ControlType,
  DynamicForm,
  DynamicFormGroupComponent,
  FormBuilderService,
  FormRule,
  FormRuleFactory,
} from 'src/app/shared/forms';
import { RuleAction } from 'src/app/shared/forms/models/rule-action.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-submissions-detail-view',
  standalone: true,
  imports: [
    PanelContainerComponent,
    PanelHeaderComponent,
    PanelBodyComponent,
    ReactiveFormsModule,
    DynamicFormGroupComponent,
    MatIconModule,
  ],
  templateUrl: './submissions-detail-view.component.html',
  styleUrl: './submissions-detail-view.component.scss',
})
export class SubmissionsDetailViewComponent {
  @Input() submission: Submission;
  @Output() close = new EventEmitter();

  isEditMode = false;
  form: FormGroup;
  dynamicForm: DynamicForm;
  formId: string;

  constructor(
    public formService: FormBuilderService,
    private ruleFact: FormRuleFactory,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group([]); 

    this.formService.getForm(this.formId).subscribe((i) => {
      this.dynamicForm = i;
      this.dynamicForm.bindControls(this.form);
      this.runRuleSet();
    });

    this.form.valueChanges.subscribe((i) => {
      this.formService.resetValidations(
        this.dynamicForm.getControls(),
        this.dynamicForm.getGroups()
      );

      this.runRuleSet();
    });
  }

  toggleEditMode() {
    this.isEditMode = true;
    //this is temporary solution to load/test forms on edit.
    this.formId = this.route.snapshot.queryParamMap.get('id');
    this.ngOnInit();
  }

  saveChanges() {
    this.isEditMode = false;
  }

  private runRuleSet() {
    let controls = this.dynamicForm.getControls();
    let groups = this.dynamicForm.getGroups().filter((i) => i.label);
    let rules = this.formService.generateRuleSet(
      this.dynamicForm.rules,
      controls
    );

    rules.forEach((i) => {
      this.formService.applyRule(i, controls, groups);
    });

    //Custom Rules
    rules = this.formService.generateRuleSet(this.getCustomRules(), controls);
    rules.forEach((i) => {
      this.formService.applyRule(i, controls, groups);
    });

    controls
      .flatMap((i) => i.formControls)
      .forEach((i) =>
        i.formControl.updateValueAndValidity({
          onlySelf: true,
        })
      );
  }

  closePanel() {
    this.isEditMode = false;
    this.close.emit();
  }

  private getCustomRules(): FormRule[] {
    return [
      this.ruleFact.createRule("'{isEBuit}' === 'true' && {pointsTotal} < 10", [
        {
          action: RuleAction.SET_VALUE,
          affectingType: 'group',
          applyTo: 'Design Package',
          value:
            'Design Package - Not enough points have been claimed to submit this home.',
        },
        {
          action: RuleAction.MAKE_INVALID,
          affectingType: 'control',
          applyTo: 'pointsTotal',
        },
      ]),
      this.ruleFact.createRule("'{isEBuit}' === 'true'", [
        {
          action: RuleAction.SET_MIN_VALUE,
          affectingType: 'control',
          applyTo: 'pointsTotal',
          value: 10,
        },
      ]),
      this.ruleFact.createRule(
        "'{isEBuit}' === 'true' && {pointsTotal} >= 10",
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'group',
            applyTo: 'Design Package',
            value: 'Design Package',
          },
        ]
      ),
    ];
  }
}
