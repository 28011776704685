import { Component, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgStyle, NgClass } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterModule } from '@angular/router';
import { NavService } from '../services/nav.service';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
  selector: 'nav-item',
  imports: [NgStyle, NgClass, MatRippleModule, RouterModule],
  template: `
  @if(!isMobile) {
    @if(navbarHidden) {
      <button
      [routerLink]="[url]"
      class="side-nav-item"
    >
      <div
        class="icon"
        [attr.name]="name"
        [ngStyle]="{
          '-webkit-mask-image': 'url(' + iconUrl + ')'
        }"
      ></div>
    </button>

    } @else {
      <button
      [routerLink]="[url]"
      matRipple
      [matRippleColor]="rippleColor"
      [ngClass]="active ? 'nav-item active' : 'nav-item'"
    >
      <div
        class="icon"
        [attr.name]="name"
        [ngStyle]="{
          '-webkit-mask-image': 'url(' + iconUrl + ')'
        }"
      ></div>
      <div class="nav-item-text">{{ name }}</div>
    </button>
     
    }
  } @else {
    <button
      [routerLink]="[url]"
      class="mobile-nav-item"
    >
      <div
        class="icon"
        [attr.name]="name"
        [ngStyle]="{
          '-webkit-mask-image': 'url(' + iconUrl + ')'
        }"
      ></div>
    </button>
  }
  `,
  standalone: true,
  styles: [
    `
      .nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 95px;
        width: 100%;
        margin: 0;
        padding: 0;

        background: none;
        border-radius: 0;

        color: var(--nav-item-text-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .icon {
          background: var(--nav-item-text-color);
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;
          margin-bottom: 8px;

          width: 35px;
          height: 32px;
        }
        .icon[name='Energy Credits'] {
          width: 50px;
          height: 47px;
        }

        &:hover {
          cursor: pointer;
          background: var(--nav-item-background-hover);
        }

        &.active {
          color: var(--nav-item-active-text-color);
          background: var(--nav-item-active-background-color);
          border-left: var(--nav-item-active-border);

          &:hover {
            background: var(--nav-item-background-hover);
          }

          > .icon {
            background: var(--nav-item-active-text-color);
          }
        }
      }

      .mobile-nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 95px;
        width: 100%;
        margin: 0;
        padding: 0;

        background: none;
        border-radius: 0;

        .icon {
          background: var(--nav-item-text-color);
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;
          margin-bottom: 8px;

          width: 35px;
          height: 32px;
        }
        .icon[name='Energy Credits'] {
          width: 50px;
          height: 47px;
        }

        &:hover {
          cursor: pointer;
          filter: drop-shadow(5px 5px 5px #222);
          //background: var(--nav-item-background-hover);
        }

        &.active {
          color: var(--nav-item-active-text-color);
          //background: var(--nav-item-active-background-color);
          border-left: var(--nav-item-active-border);

          &:hover {
            background: var(--nav-item-background-hover);
          }

          > .icon {
            background: var(--nav-item-active-text-color);
          }
        }
      }

      .side-nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 60px;
        width: 100%;
        margin: 0;
        padding: 0;

        background: none;
        border-radius: 0;

        .icon {
          background: var(--nav-item-text-color);
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;
          margin-bottom: 8px;

          width: 35px;
          height: 32px;
        }
        .icon[name='Energy Credits'] {
          width: 50px;
          height: 47px;
        }

        &:hover {
          cursor: pointer;
          filter: drop-shadow(5px 5px 5px #222);
          //background: var(--nav-item-background-hover);
        }

        &.active {
          color: var(--nav-item-active-text-color);
          //background: var(--nav-item-active-background-color);
          border-left: var(--nav-item-active-border);

          &:hover {
            background: var(--nav-item-background-hover);
          }

          > .icon {
            background: var(--nav-item-active-text-color);
          }
        }
      }
    `,
  ],
})
export class NavItemComponent {
  @Input() name: string = '';
  @Input() iconUrl: string = '';
  @Input() active: boolean = false;
  @Input() url: string = '';
  @Input() navbarHidden: boolean = false;

  getScreenWidth: number;
  getScreenHeight: number;
  isMobile: boolean = false;

  rippleColor: string = 'rgba(255,255,255,0.08)';
  @HostListener('click') onClick() {
    this.navService.setActive(this.name);
  }

  //listens for a screen size change for displaying mobile icons
  @HostListener('window:resize', ['$event']) 
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
  }

  constructor(private navService: NavService,
    private breakPointService: BreakpointService
  ) {
  }

  ngOnInit() {
    //check screen size for mobile Icons
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
  }
}
