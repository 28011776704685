import { Component, Input } from '@angular/core';

@Component({
  selector: 'content-panel',
  standalone: true,
  imports: [],
  template: `
    <div class="container">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }

      .container {
        display: flex;
        height: calc(100vh - 130px);
        flex-direction: column;
        align-items: flex-start;
        background: var(--panel-background-color);
        border: 1px solid var(--panel-border-color);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
      }
    `,
  ],
})
export class PanelContainerComponent {
  @Input() isOpen: boolean = false;
}
