<content-panel>
  <panel-header heading="Users">
    <button mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>filter_alt</mat-icon>
    </button>
  </panel-header>

  <panel-body>
    <div class="users-container">
      @if(isDetailOpen) { @for (element of dataSource.data; track element.Id) {
      <section
        mat-ripple
        class="list-row-container"
        [ngClass]="user?.Id === element.Id ? 'active' : ''"
        (click)="rowSelected(element.Id)"
      >
        <h2 class="primary-content-container">
          {{ element.FirstName }} {{ element.LastName }}
        </h2>
        <div class="secondary-content-container">
          <div [style.width.%]="60">
            Email:
            <div class="secondary-data-bold">{{ element.Email }}</div>
          </div>
          <div>
            Office:
            <div class="secondary-data-bold">{{ element.DefaultLocation }}</div>
          </div>
        </div>
      </section>
      } } @else {
      <section>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange.emit($event)">
          @for (column of usersTableColumns; track column) {
          <ng-container matColumnDef={{column.id}}>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <b>{{ column.label }}</b>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element[column.id] }}
            </td>
          </ng-container>
        }
          <tr mat-header-row *matHeaderRowDef="mapUsersDisplayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: mapUsersDisplayedColumns"
            (click)="rowSelected(row.Id)"
          ></tr>
        </table>
      </section>
      }
    </div>
  </panel-body>
  @if(!isDetailOpen){
  <mat-paginator
    [pageSizeOptions]="pagination.pageSizeOptions"
    [pageSize]="pagination.pageSize"
    [length]="pagination.totalCount"
    [showFirstLastButtons]="true"
    (page)="pageChange.emit($event)"
  >
  </mat-paginator>
  } @else {
  <mat-paginator
    [pageSize]="pagination.pageSize"
    [length]="pagination.totalCount"
    (page)="pageChange.emit($event)"
  >
  </mat-paginator>
  }
</content-panel>
