import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe, NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { User } from '../models/user';
import { UsersService } from '../services/users.service';
import {
  MatTableDataSource,
  MatTableModule,
  MatCheckboxModule,
  MatIconModule,
} from 'src/app/shared/material';
import { AssignedFacilityDataSource, Facility } from 'src/app/shared/models';
import { TableColumnPipe } from 'src/app/shared/pipes';
import { PanelBodyComponent, PanelContainerComponent, PanelHeaderComponent, panelSlider } from 'src/app/shared/content-panels';
import { SpinnerService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-detail-view',
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    TableColumnPipe,
    MatCheckboxModule,
    MatTableModule,
    MatIconModule,
    PanelContainerComponent,
    PanelBodyComponent,
    PanelHeaderComponent,
  ],
  animations: [panelSlider],
  templateUrl: './user-detail-view.component.html',
  styleUrl: './user-detail-view.component.scss',
})
export class UserDetailViewComponent {
  @Input() user: User;
  @Output() close = new EventEmitter();

  isEditMode = false;
  allAssigned = false;
  assignedFacilities = new MatTableDataSource<AssignedFacilityDataSource>([]);
  private _facilitiesCollection: Facility[];
  private _subscriptions: Subscription[] = [];

  constructor(
    public userSrv: UsersService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    var sub = this.userSrv.facilities$.subscribe((result) => {
      this._facilitiesCollection = result
        .map((i) => new Facility(i))
        .sort((a, b) => parseInt(a.KeyChain.LegacyId) - parseInt(b.KeyChain.LegacyId));
    });

    this._subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  toggleEditMode() {
    this.isEditMode = true;

    this.assignedFacilities.data =
      this.userSrv.generateAssignedFacilitiesDataSource(
        this.user,
        this._facilitiesCollection
      );

    this.allAssigned =
      this.assignedFacilities.data.filter((i) => !i.Assigned)?.length == 0;
  }

  closePanel() {
    this.isEditMode = false;
    this.close.emit();
  }

  updateAssignedFacility(fac: AssignedFacilityDataSource) {
    this.userSrv.updateFacilityAssignments(
      this.user,
      this._facilitiesCollection,
      this.assignedFacilities.data,
      fac
    );

    this.allAssigned =
      this.assignedFacilities.data.filter((i) => !i.Assigned)?.length == 0;
  }

  assignAllFacilities(checked: boolean) {
    this.assignedFacilities.data.map((i) => {
      i.Assigned = checked;
      this.updateAssignedFacility(i);
    });

    this.allAssigned = checked;
  }

  updateDefaultAssignedFacility(fac: AssignedFacilityDataSource) {
    this.userSrv.updateDefaultAssignedFacility(
      this.user,
      this._facilitiesCollection,
      this.assignedFacilities.data,
      fac
    );
  }

  saveChanges() {
    this.isEditMode = false;
    this.spinnerService.show();
    this.userSrv.saveFacilityAssignments(this.user);
  }
}
