import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatButton, MatLabel } from 'src/app/shared/material';
import { DrawerService } from 'src/app/shared/services';
import {
  ControlType,
  DynamicFormControl,
  DynamicFormControlComponent,
  DynamicFormGroup,
  DynamicFormGroupComponent,
  FormGroupFactory,
} from 'src/app/shared/forms';
import { FilterService } from '../../services/filter.service';
import { ActiveFilter } from '../../models/active-filter.model';
import { ActiveFilterFactory } from '../../services/active-filter-factory.service';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';

@Component({
  selector: 'filter-drawer',
  standalone: true,
  imports: [
    MatButton,
    MatLabel,
    ReactiveFormsModule,
    DynamicFormGroupComponent,
    DynamicFormControlComponent,
  ],
  templateUrl: './filter-drawer.component.html',
  styleUrl: './filter-drawer.component.scss',
})
export class FilterDrawerComponent implements OnInit, OnDestroy {
  ControlType = ControlType;
  formGroup!: FormGroup;
  controlGroups: DynamicFormGroup[] = [];
  controls: DynamicFormControl[] = [];

  private _subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public service: FilterService,
    private drawerSvc: DrawerService,
    private groupFact: FormGroupFactory,
    private afFact: ActiveFilterFactory,
    private breakPointService: BreakpointService
  ) {}

  ngOnInit(): void {
    this.setFilters(this.service.filterFormControls);
    let sub = this.service.filterFormControlChanges$.subscribe((filters) => {
      this.setFilters(filters);
    });
    this._subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  apply() {
    let activeFilters: ActiveFilter[] = [];
    this.controls.forEach((i) => {
      switch (i.controlType) {
        case ControlType.MULTISELECT:
          activeFilters = [
            ...activeFilters,
            ...this.getMultiSelectActiveFilters(i),
          ];
          break;
        case ControlType.DATERANGE:
          activeFilters = [
            ...activeFilters,
            ...this.getDateRangeActiveFilters(i),
          ];
          break;
        default:
          let filters = this.formGroup.controls[i.propertyBinding].value
            ? [
                this.afFact.createActiveFilter(
                  i.controlType,
                  i.label,
                  this.formGroup.controls[i.propertyBinding].value,
                  i.propertyBinding
                ),
              ]
            : [];

          activeFilters = [...activeFilters, ...filters];
      }
    });

    this.service.setActiveFilters(activeFilters);
    this.drawerSvc.closeFilter();
  }

  resetFilter(controlType: ControlType) {
    this.controls
      .filter((i) => i.controlType === controlType)
      .flatMap((i) => i.formControls)
      .forEach((ctrl) => {
        ctrl.formControl.setValue(null);
      });
  }

  onResetAll(): void {
    this.controls
      .map((i) => i.controlType)
      .forEach((i) => {
        this.resetFilter(i);
      });
  }

  getControls(group: DynamicFormGroup): DynamicFormControl[] {
    let controls = group.getControls();
    return controls;
  }

  private buildFormGroup() {
    this.formGroup = this.formBuilder.group([]);
    this.setTextBoxControls(
      this.controls.filter((i) => i.controlType === ControlType.INPUT)
    );
    this.setDateRangeControls(
      this.controls.filter((i) => i.controlType === ControlType.DATERANGE)
    );
    this.setMultiSelectControls(
      this.controls.filter((i) => i.controlType === ControlType.MULTISELECT)
    );
  }

  private setTextBoxControls(controls: DynamicFormControl[]): void {
    controls
      .flatMap((i) => i.formControls)
      .forEach((ctrl) => {
        let actives = this.service.activeFilters?.filter((i) =>
          i.propertyBinding.includes(ctrl.propertyBinding)
        )[0];

        if (actives) {
          ctrl.formControl.setValue(actives.value);
        }
        this.formGroup.addControl(ctrl.propertyBinding, ctrl.formControl);
      });
  }

  private setDateRangeControls(controls: DynamicFormControl[]): void {
    controls.forEach((ctrl) => {
      let actives = this.service.activeFilters?.filter((i) =>
        i.propertyBinding.includes(ctrl.propertyBinding)
      )[0];

      ctrl.formControls.forEach((i) => {
        if (actives && i.propertyBinding.toLowerCase().includes('start')) {
          i.formControl.setValue(actives.value?.start);
        }
        if (actives && i.propertyBinding.toLowerCase().includes('end')) {
          i.formControl.setValue(actives.value?.end);
        }
        this.formGroup.addControl(i.propertyBinding, i.formControl);
      });
    });
  }

  private setMultiSelectControls(controls: DynamicFormControl[]): void {
    controls.forEach((ctrl) => {
      let actives = this.service.activeFilters?.filter((i) =>
        i.propertyBinding.includes(ctrl.propertyBinding)
      );
      ctrl.formControls.forEach((i) => {
        if (!i.propertyBinding.includes('autocomplete')) {
          i.formControl.setValue(actives?.map((i) => i.value));
        }
        this.formGroup.addControl(i.propertyBinding, i.formControl);
      });
    });
  }

  private buildFilterGroup(
    heading?: string,
    controls?: DynamicFormControl[],
    handler?: Function
  ): DynamicFormGroup {
    let group = this.groupFact.createFormGroup(heading, 'row', {}, controls);
    group.addHandler('on_reset_click', handler);
    return group;
  }

  private buildDynamicFormGroups() {
    let definedGroups = [
      {
        name: 'Text Filters',
        controls: this.controls.filter(
          (i) => i.controlType === ControlType.INPUT
        ),
        handler: () => {
          this.resetFilter(ControlType.INPUT);
        },
      },
      {
        name: 'Multi-Select Filters',
        controls: this.controls.filter(
          (i) => i.controlType === ControlType.MULTISELECT
        ),
        handler: () => {
          this.resetFilter(ControlType.MULTISELECT);
        },
      },
      {
        name: 'Date Filters',
        controls: this.controls.filter(
          (i) =>
            i.controlType === ControlType.DATE ||
            i.controlType === ControlType.DATERANGE
        ),
        handler: () => {
          this.resetFilter(ControlType.DATERANGE);
          this.resetFilter(ControlType.DATE);
        },
      },
    ];

    definedGroups.forEach((i) => {
      if (i.controls.length > 0) {
        this.controlGroups = [
          ...this.controlGroups,
          this.buildFilterGroup(i.name, i.controls, i.handler),
        ];
      }
    });
  }

  private setFilters(filters: DynamicFormControl[]) {
    this.controls = filters;
    this.controlGroups = [];
    this.buildDynamicFormGroups();
    this.buildFormGroup();
  }

  private getDateRangeActiveFilters(
    control: DynamicFormControl
  ): ActiveFilter[] {
    let startValue = control.formControls.filter((i) =>
      i.propertyBinding.includes('start')
    )[0].formControl.value;
    let endValue = control.formControls.filter((i) =>
      i.propertyBinding.includes('end')
    )[0].formControl.value;

    return startValue && endValue
      ? [
          this.afFact.createActiveFilter(
            control.controlType,
            control.label,
            { start: startValue, end: endValue },
            control.propertyBinding
          ),
        ]
      : [];
  }

  private getMultiSelectActiveFilters(
    control: DynamicFormControl
  ): ActiveFilter[] {
    let selectOptions = control.customOptions.selectOptions;
    let formControl = control.formControls.find(
      (i) => !i.propertyBinding.includes('autocomplete')
    ).formControl;

    return (
      formControl.value?.map((i: string[]) => {
        return this.afFact.createActiveFilter(
          control.controlType,
          control.label,
          i,
          control.propertyBinding,
          selectOptions.find((opt: any) => opt.label === i)?.value
        );
      }) || []
    );
  }
}
