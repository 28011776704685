import { Component, ViewChild, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FilterDrawerComponent } from 'src/app/shared/filtering';
import { NavBarComponent } from 'src/app/shared/navigation';
import { UserSettingsComponent } from 'src/app/shared/user-settings';
import {
  PageContentComponent,
  PageHeaderComponent,
} from 'src/app/shared/page-container';
import {
  MatDrawer,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatIcon
} from 'src/app/shared/material';
import {
  DrawerService,
  SpinnerService,
  ThemeService,
} from 'src/app/shared/services';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { NavBarMobileComponent } from "../../shared/navigation/components/nav-bar-mobile/nav-bar-mobile.component";


@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    CommonModule,
    NavBarComponent,
    PageHeaderComponent,
    PageContentComponent,
    RouterOutlet,
    MatProgressSpinnerModule,
    MatSidenavModule,
    UserSettingsComponent,
    FilterDrawerComponent,
    NavBarMobileComponent,
    MatIcon
],
})
export class HomeComponent {
  @ViewChild(MatDrawer) drawer: MatDrawer;

  public getScreenWidth: any;
  public getScreenHeight: any;
  public isMobile: boolean = false;
  public below1k: boolean = false;

  constructor(
    public themeService: ThemeService,
    public drawerService: DrawerService,
    public spinnerService: SpinnerService,
    public breakPointService: BreakpointService,
    public drawerSvc: DrawerService,
  ) {}

  //listens for a screen size change for displaying nav-bar or nav-bar-mobile
  @HostListener('window:resize', ['$event']) 
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
    this.below1k = this.breakPointService.below1000Check(this.getScreenHeight, this.getScreenWidth);
  }

  ngOnInit() {
    //check screen size for nav-bar
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
    this.below1k = this.breakPointService.below1000Check(this.getScreenHeight, this.getScreenWidth);
  }

  updateDrawerState() {
    this.drawerService.state.set(false);
  }
}
