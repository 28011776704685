import { Component, OnDestroy, OnInit, ViewChild, effect } from '@angular/core';
import { User } from '../models/user';
import { UsersService } from '../services/users.service';
import { Pagination } from 'src/app/shared/models';
import { panelSlider } from 'src/app/shared/content-panels/animations';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AlertActions,
  WebSocketService,
} from 'src/app/shared/services/web-socket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDetailViewComponent } from '../user-detail-view/user-detail-view.component';
import { UsersListViewComponent } from '../users-list-view/users-list-view.component';
import { NgIf } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import {
  ActiveFiltersComponent,
  FilterService,
} from 'src/app/shared/filtering';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-users-view-container',
  standalone: true,
  templateUrl: './users-view-container.component.html',
  styleUrl: './users-view-container.component.scss',
  imports: [
    NgIf,
    ActiveFiltersComponent,
    UsersListViewComponent,
    UserDetailViewComponent,
  ],
  animations: [panelSlider],
})
export class UsersViewContainerComponent implements OnInit, OnDestroy {
  isDetailOpen = false;
  user: User;

  private _subscriptions: Subscription[] = [];
  private _pagination: Pagination = {
    paging: true,
  };

  constructor(
    public userSrv: UsersService,
    private filterSvc: FilterService,
    private route: ActivatedRoute,
    private router: Router,
    private wsService: WebSocketService,
    private toaster: MatSnackBar,
    private spinnerService: SpinnerService
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  ngOnInit(): void {
    this.filterSvc.initialize('users_list_filters');
    this.getUsers();

    let sub = this.filterSvc.activeFilterChanges$.subscribe(() =>
      this.getUsers()
    );
    this._subscriptions.push(sub);

    sub = this.wsService.connection$?.subscribe((i) => {
      if (i.Type === 'UserFacilityAssignment') {
        if (i.Action === AlertActions.PROCESSED) {
          this.spinnerService.hide();
          this.getUsers();
        }
        if (AlertActions.isError(i.Action)) {
          this.toaster.open(i.Message, i.Action);
          this.spinnerService.hide();
        }
      }
    });

    this._subscriptions.push(sub);
  }

  onPageSort(event: Sort) {
    this._pagination.sortBy = event.active;
    this._pagination.sortDirection = event.direction;

    this.userSrv.getUsers(this._pagination);
  }

  onPageChange(event: PageEvent) {
    this._pagination.pageNumber = event.pageIndex + 1;
    this._pagination.pageSize = event.pageSize;
    this.userSrv.getUsers(this._pagination);
  }

  onSortChange(event: Sort) {
    this._pagination.sortBy = event.active;
    this._pagination.sortDirection = event.direction;
    this.userSrv.getUsers(this._pagination);
  }

  selectUser(user: User) {
    let queryParams: Params = { id: this.user?.Id };
    this.user = user;
    if (this.user) {
      this.isDetailOpen = true;
      queryParams = { id: this.user?.Id };

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    } else {
      this.closeDetail();
    }
  }

  closeDetail() {
    this.isDetailOpen = false;
    this.user = null;
    let queryParams: Params = { id: null };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  private getUsers() {
    this.userSrv.getUsers(
      this.filterSvc.activeFilters
        ? this.filterSvc.convertToQueryFilter(this.filterSvc.activeFilters)
        : this._pagination
    );
  }
}
