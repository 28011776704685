import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ControlType } from 'src/app/shared/forms';
import { FilterService } from '../../services/filter.service';
import { SingularizePipe, TableColumnPipe } from 'src/app/shared/pipes';
import {
  MatButtonModule,
  MatChipsModule,
  MatIconModule,
} from 'src/app/shared/material';

@Component({
  selector: 'lib-active-filters',
  standalone: true,
  imports: [
    DatePipe,
    SingularizePipe,
    TableColumnPipe,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './active-filters.component.html',
  styleUrl: './active-filters.component.scss',
})
export class ActiveFiltersComponent {
  ControlType = ControlType;
  constructor(public service: FilterService) {}
}
