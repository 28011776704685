import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './modules/home/home.component';
import { ErrorComponent } from './modules/error/error.component';
import { UsersViewContainerComponent } from './modules/users/view-container/user-view-container.component';
import { FormBuilderComponent } from './modules/playground/form-builder/form-builder.component';
import { RolesGuard } from './shared/guards/roles.guard';
import { EnergyCreditsViewContainerComponent } from './modules/energy-credits/view-container/energy-credits-view-container.component';
import { ELearnComponent } from './modules/e-learn/e-learn.component';
import { permissionConstants } from './shared/services';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'administration/users',
        component: UsersViewContainerComponent,
        canActivate: [MsalGuard, RolesGuard],
        data: {
          expectedRoles: permissionConstants.siteAdmin,
        },
      },
      {
        path: 'energy-credits',
        component: EnergyCreditsViewContainerComponent,
        canActivate: [MsalGuard, RolesGuard],
        data: {
          expectedRoles: permissionConstants.energyCredits.concat(
            permissionConstants.siteAdmin
          ),
        },
      },
      {
        path: 'e-learn',
        component: ELearnComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'playground/formbuilder',
        component: FormBuilderComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
];
