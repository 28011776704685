export enum ControlType {
  INPUT = 1,
  SELECT = 2,
  CHECKBOX = 3,
  RADIOBUTTON = 4,
  PILL = 5,
  TEXTAREA = 6,
  MULTISELECT = 7,
  DATE = 8,
  TOGGLE = 9,
  DATERANGE = 10,
  INCREMENTING = 11,
  READONLY = 12,
}
