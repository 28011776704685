<content-panel>
  <panel-header [heading]="'Submission_Serial_Goes_Here'">
    @if(isEditMode) {
    <button mat-icon-button [disabled]="form.invalid" (click)="saveChanges()">
      <mat-icon>save</mat-icon>
    </button>
    } @else {
    <button mat-icon-button (click)="toggleEditMode()">
      <mat-icon>edit</mat-icon>
    </button>
    }
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </panel-header>
  <panel-body class="detail-view-container">
    @if(isEditMode){
      <div class="content">
        <form [formGroup]="form">
          @for(group of dynamicForm?.formGroups; track group){
          @if(!group.hidden) {
          <lib-dynamic-form-group [group]="group">
          </lib-dynamic-form-group>
          } }
        </form>
      </div>
    }
  </panel-body>
</content-panel>
