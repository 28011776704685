import { Injectable } from '@angular/core';
import { ControlType } from '../../forms';
import { ActiveFilter } from '../models/active-filter.model';

@Injectable({ providedIn: 'root' })
export class ActiveFilterFactory {
  createActiveFilter(
    controlType?: ControlType,
    label?: string,
    value?: any,
    propertyBinding?: string,
    propertyValue?: any
  ): ActiveFilter {
    let af = new ActiveFilter();
    af.controlType = controlType;
    af.label = label;
    af.propertyBinding = propertyBinding;
    af.propertyValue = propertyValue ? propertyValue : value;
    af.value = value;
    return af;
  }
}
