import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'lib-date-picker-control',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './date-picker-control.component.html',
  styleUrl: './date-picker-control.component.scss',
})
export class DatePickerControlComponent {
  @Input() control: DynamicFormControl;

  inputControl: FormControl;

  ngOnInit() {
    this.inputControl = this.control.formControls[0].formControl;
  }
}
