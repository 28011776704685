import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { Submission } from '../models/submission.model';
import { MsalService } from '@azure/msal-angular';
import { User } from '../../users/models/user';
import { Facility, PaginatedList } from 'src/app/shared/models';
import { GET_ALL_SUBMISSIONS } from 'src/app/shared/graph-queries';

@Injectable({
  providedIn: 'root',
})
export class SubmissionsService {
  submissions$ = new Subject<PaginatedList<Submission>>();
  facilities$ = new Subject<Facility[]>();

  constructor(private apolloClient: Apollo, private authService: MsalService) {}

  getSubmissions(filter?: any): void {
    this.apolloClient
      .query({
        query: GET_ALL_SUBMISSIONS,
        variables: {
          filter: JSON.stringify(filter),
          id: this.authService.instance.getAllAccounts()[0].idTokenClaims.oid,
        },
      })
      .subscribe((response: any) => {
        let result = response.data.GetAllEnergyCreditSubmissions;
        let submissions = new PaginatedList(result);
        submissions.Items = submissions.Items.map(
          (x: Submission) => new Submission(x)
        );

        //Available Facilities for filter dropdown.
        let loggedInUser: User = response.data.GetLoggedInUser;
        this.submissions$.next(submissions);
        this.facilities$.next(
          loggedInUser.FacilityAssignments.map((i) => new Facility(i.Facility))
        );
      });
  }
}
