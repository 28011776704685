@if(group.label) {
  <section class="header">
    <div>{{ group.label }}</div>
    <ng-template></ng-template>
  </section>
}
<section [class]="group.layoutDirection">
    @for(group of nestedGroups; track group) { 
      @if(!group.hidden) {
        <lib-dynamic-form-group class="item-container"
        [group]="group"
        [nested]="true"
        [attr.size]="
          group.customOptions.metadata?.containerSize
            ? group.customOptions.metadata?.containerSize
            : 'auto'
        "
        [ngClass]="
          group.customOptions.metadata?.showDivider || group.label
            ? 'border-left'
            : 'no-border'
        "
        ></lib-dynamic-form-group>
    }  } @if(controls?.length > 0) {
        <section class="column">
        @for(control of controls; track control) { 
          @if(!control.hidden) {
            @if(control.propertyBinding === 'version') {
              <lib-dynamic-form-control class="version"
              [attr.controlType]="control.controlType"
              [control]="control"
              >
              </lib-dynamic-form-control>
            } @else {
              <lib-dynamic-form-control class="control-item"
              [attr.controlType]="control.controlType"
              [control]="control"
              >
              </lib-dynamic-form-control>
            }
            
          } 
    }
  </section>
  }
</section>