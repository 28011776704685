import { Injectable } from '@angular/core';
import { DynamicFormGroup } from '../models/dynamic-form-group.model';
import { DynamicFormControl } from '../models/dynamic-form-control.model';

@Injectable({ providedIn: 'root' })
export class FormGroupFactory {
  /**
   * Creates a new DynamicFormGroup.
   * @param label
   * @param layoutDirection
   * @param customOptions
   * @param children
   * @returns
   */
  createFormGroup(
    label?: string,
    layoutDirection?: 'column' | 'row',
    customOptions?: any,
    children?: DynamicFormGroup[] | DynamicFormControl[]
  ): DynamicFormGroup {
    let group = new DynamicFormGroup();

    group.label = label;
    group.name = label;
    group.layoutDirection = layoutDirection ? layoutDirection : 'row';
    group.children = children ? children : [];

    if (customOptions) {
      if (!Object.hasOwn(customOptions, 'isVisibleByDefault')) {
        customOptions['isVisibleByDefault'] = true;
      }
    } else {
      customOptions = {
        isVisibleByDefault: true,
      };
    }

    group.customOptions = customOptions;
    if (!group.customOptions.isVisibleByDefault) {
      group.hidden = true;
    }
    return group;
  }
}
