import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { breakpointConstants } from "./constants";

@Injectable({
    providedIn: 'root',
})


export class BreakpointService {
    constructor(private responsive: BreakpointObserver) {}

    //check if screen size is 800px or smaller
    smallScreenCheck(height: number, width: number) {
        if(width <= breakpointConstants.smallScreenSize) {
            return true;
        } else {
            return false;
        }


    }

    //check if screen size is mobile (600px or smaller)
    mobileCheck(height: number, width: number) {
        if(width <= breakpointConstants.mobileScreenSize) {
            return true;
        } else {
            return false;
        }

    }

    //check for screen size below 1150 - this check is used to fix the clumping of columns in the credit submissions table
    below1150Check(height: number, width: number) {
        if(width <= breakpointConstants.below1150Size) {
            return true;
        } else {
            return false;
        }
    }

    //check for sizing under 1000
    below1000Check(height:number, width: number) {
        if(width <= breakpointConstants.below1000Size) {
            return true;
        } else {
            return false;
        }
    }
    
}