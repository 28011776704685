import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { UserPreference } from './models/user-preference';
import {
  AlertActions,
  DrawerService,
  SpinnerService,
  ThemeService,
  TokenClaimsService,
  WebSocketService,
  localStorageConstants,
} from 'src/app/shared/services';
import {
  MatCardModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSnackBar,
} from 'src/app/shared/material';

@Component({
  selector: 'lib-user-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatDividerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss',
})
export class UserSettingsComponent implements OnInit {
  userPreference: UserPreference;
  userPreferenceForm: FormGroup;
  defaultUrl: string = '';
  theme: string = this.themeService.currentTheme;
  azureOId: string = '';

  formFields = {
    defaultUrl: 'defaultUrl',
    theme: 'theme',
  };

  constructor(
    public themeService: ThemeService,
    private fb: FormBuilder,
    private socketService: WebSocketService,
    private claimsService: TokenClaimsService,
    private toaster: MatSnackBar,
    public settingsDrawer: DrawerService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.userPreferenceForm = this.fb.group({
      defaultUrl: new FormControl({
        value: this.defaultUrl,
        disabled: false,
      }),
      theme: new FormControl({
        value: this.theme,
        disabled: false,
      }),
    });

    this.azureOId = this.claimsService.tokenClaims$.oid;
    this.setUserPreferences();
  }

  onSubmit(formData: any): void {
    var preference: UserPreference = {
      AzureOid: this.azureOId,
      Value: {
        theme: formData.theme,
        defaultUrl: formData.defaultUrl?.replace(/ /g, ''),
      },
    };
    this.spinnerService.show();
    this.socketService.send({
      metadata: {
        eventType: 'UPDATE',
        domain: 'UserPreference',
      },
      data: preference,
    });
    localStorage.setItem(
      localStorageConstants.USER_PREFERENCE_KEY,
      JSON.stringify(preference)
    );
    this.themeService.loadUserTheme();
    this.settingsDrawer.state.update((value: boolean) => (value = !value));
  }

  sub = this.socketService.connection$?.subscribe((i) => {
    if (i.Type === 'UserPreference') {
      if (i.Action === AlertActions.PROCESSED) {
        this.spinnerService.hide();
      }
      if (i.Action === AlertActions.PROCESSING_ERROR) {
        this.spinnerService.hide();
        this.toaster.open(i.Message);
      }
    }
  });

  setFormInput(inputName: string, value: any): void {
    this.userPreferenceForm.get(inputName).patchValue(value || null);
  }

  loadTheme(theme: string) {
    this.themeService.set(theme);
  }

  private setUserPreferences(): void {
    let userPreference = JSON.parse(
      localStorage.getItem(localStorageConstants.USER_PREFERENCE_KEY)
    );

    if (userPreference) {
      this.theme = userPreference.theme;
      this.defaultUrl = userPreference.defaultUrl;

      this.setFormInput(this.formFields.theme, this.theme);
      this.setFormInput(this.formFields.defaultUrl, this.defaultUrl);
    }
  }
}
