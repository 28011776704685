<!-- toolbar across the top -->
<mat-toolbar class="toolbar top">
    <img src="./assets/logos/ClaytonLogo.png" alt="claytonLogo" class="mobileLogo">
    <span class="spacer"></span>
    <div>
        <page-header></page-header>
    </div>
</mat-toolbar>
<!-- toolbar across the bottom -->
<mat-toolbar class="toolbar bottom">
    <ng-container>
        <div *ngFor="let item of navItems">
            <nav-item 
            [name]="item.Name"
            [active]="item.Active"
            [iconUrl]="item.IconUrl"
            [url]="item.Url"></nav-item>
        </div>
    </ng-container>
</mat-toolbar>
