import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subscription } from 'rxjs';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';
import { provideNativeDateAdapter } from '@angular/material/core';
import { FilterService } from 'src/app/shared/filtering';

@Component({
  selector: 'lib-date-range-control',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './date-range-control.component.html',
  styleUrl: './date-range-control.component.scss',
})
export class DateRangeControlComponent {
  @Input() control: DynamicFormControl;

  startDateControl: FormControl;
  endDateControl: FormControl;

  private _subscriptions: Subscription[] = [];

  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.startDateControl = this.control.formControls.find((i) =>
      i.propertyBinding.includes('start_input_ctrl')
    )?.formControl;
    this.endDateControl = this.control.formControls.find((i) =>
      i.propertyBinding.includes('end_input_ctrl')
    )?.formControl;

    let sub = this.filterService.activeFilterRemoved$.subscribe({
      next: (i) => {
        if (i.propertyBinding === this.control.propertyBinding) {
          this.startDateControl.setValue(null);
          this.endDateControl.setValue(null);
        }
      },
      error: (e) => console.log(e),
    });

    this._subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }
}
