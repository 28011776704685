export const localStorageConstants: any = {
    USER_PREFERENCE_KEY: 'userPreference'
}

export const permissionConstants: any = {
    energyCredits: ['EnergyCreditsAdmin', 'EnergyCreditsUser'],
    siteAdmin: 'SiteAdmin'
}

export const breakpointConstants: any = {
    smallScreenSize: 800,
    mobileScreenSize: 600,
    below1150Size: 1150,
    below1000Size: 1000
}