<div class="panel-flex">
  <content-panel>
    <panel-header heading="Form Builder"></panel-header>
    <panel-body>
      <div class="content">
        <form [formGroup]="form">
          @if(dynamicForm) { @for(group of dynamicForm?.formGroups; track
          group){ @if(!group.hidden) {
          <lib-dynamic-form-group [group]="group"> </lib-dynamic-form-group>
          } } }
        </form>
      </div>
    </panel-body>
  </content-panel>
</div>
