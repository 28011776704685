import { Injectable } from '@angular/core';
import { TokenClaimsService } from './token-claims.service';
import { BehaviorSubject, Observable, Subject, mergeMap, of } from 'rxjs';
import { NavItem } from '../navigation/models/nav-item.model';
import { subscribe } from 'graphql';

@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  isAdmin = new BehaviorSubject<boolean>(false);
  isAdmin$ = this.isAdmin.asObservable();

  constructor(private claimsSrv: TokenClaimsService) {}

  setIsAdmin() {
    if (this.claimsSrv.isAdmin) {
      this.isAdmin.next(this.claimsSrv.isAdmin);
    }
  }
}
