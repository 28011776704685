import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicFormControl, ControlType } from 'src/app/shared/forms';
import { ActiveFilter } from '../models/active-filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private _initializationKey: string = '';
  private _filterFormControlsMap: Map<string, DynamicFormControl[]>;
  private _activeFiltersMap: Map<string, ActiveFilter[]>;
  public filterFormControls: DynamicFormControl[];
  public activeFilters: ActiveFilter[];

  activeFilterChanges$: Subject<ActiveFilter[]>;
  filterFormControlChanges$: Subject<DynamicFormControl[]>;
  activeFilterRemoved$: Subject<ActiveFilter>;

  constructor() {
    this._filterFormControlsMap = new Map<string, DynamicFormControl[]>();
    this._activeFiltersMap = new Map<string, ActiveFilter[]>();
    this.activeFilterChanges$ = new Subject<ActiveFilter[]>();
    this.filterFormControlChanges$ = new Subject<DynamicFormControl[]>();
    this.activeFilterRemoved$ = new Subject<ActiveFilter>();

    this.activeFilterChanges$.subscribe((i) => {
      this.activeFilters = i;
    });
    this.filterFormControlChanges$.subscribe((i) => {
      this.filterFormControls = i;
    });
  }

  initialize(key: string) {
    this._initializationKey = key;
    this._filterFormControlsMap = this.getFilterFormControlsByKey(key);
    this._activeFiltersMap = this.getActiveFiltersByKey(key);

    this.activeFilters = this._activeFiltersMap.get(key);
    this.filterFormControls = this._filterFormControlsMap.get(key);

    this.activeFilterChanges$.next(this._activeFiltersMap.get(key));
    this.filterFormControlChanges$.next(this._filterFormControlsMap.get(key));
  }

  convertToQueryFilter(filterItems: ActiveFilter[]) {
    var object: any = {};
    filterItems.forEach((i) => {
      if (i.controlType === ControlType.MULTISELECT) {
        if (!object[i.propertyBinding]) {
          object[i.propertyBinding] = [i.propertyValue];
        } else {
          object[i.propertyBinding] = [
            ...object[i.propertyBinding],
            i.propertyValue,
          ];
        }
      }
      if (i.controlType === ControlType.INPUT) {
        object[i.propertyBinding] = i.propertyValue;
      }
    });

    return object;
  }

  setFilterFormControls(filters: DynamicFormControl[]) {
    this._filterFormControlsMap.set(this._initializationKey, filters);
    this.filterFormControlChanges$.next(filters);
  }

  setActiveFilters(filters: ActiveFilter[]) {
    this._activeFiltersMap.set(this._initializationKey, filters);
    this.activeFilterChanges$.next(filters);
  }

  private getFilterFormControlsByKey(
    key: string
  ): Map<string, DynamicFormControl[]> {
    if (
      this._filterFormControlsMap.has(key) &&
      this._filterFormControlsMap.get(key).length > 0
    ) {
      return this._filterFormControlsMap;
    }
    return this._filterFormControlsMap.set(key, []);
  }

  private getActiveFiltersByKey(key: string): Map<string, ActiveFilter[]> {
    if (
      this._activeFiltersMap.has(key) &&
      this._activeFiltersMap.get(key).length > 0
    ) {
      return this._activeFiltersMap;
    }
    return this._activeFiltersMap.set(key, []);
  }

  removeActiveFilter(item: ActiveFilter) {
    this._activeFiltersMap
      .get(this._initializationKey)
      .splice(
        this._activeFiltersMap.get(this._initializationKey).indexOf(item),
        1
      );
    this.activeFilterChanges$.next(
      this._activeFiltersMap.get(this._initializationKey)
    );
    this.activeFilterRemoved$.next(item);
  }

  removeAllActiveFilters() {
    this._activeFiltersMap.get(this._initializationKey).forEach((i) => {
      this.activeFilterRemoved$.next(i);
    });

    this._activeFiltersMap.set(this._initializationKey, []);
    this.activeFilterChanges$.next(
      this._activeFiltersMap.get(this._initializationKey)
    );
  }
}
