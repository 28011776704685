import { Component, OnInit, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrawerService } from '../../shared/services/drawer.service';
import { ProfileService } from '../../shared/services/profile.service';
import { CommonModule } from '@angular/common';
import { TokenClaimsService } from '../../shared/services/token-claims.service';
import { PageHeaderService } from 'src/app/shared/services/page-header.service';
import { Router } from '@angular/router';
import { BreakpointService } from '../services/breakpoint.service';

@Component({
  selector: 'page-header',
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    CommonModule,
  ],
  template: `
    <div class="header">
      <mat-toolbar [ngClass]="{'mobile-styling': isMobile}">
        <span class="spacer"></span>
        @if (!isMobile) {
          <span> {{ profile.profile$().displayName }} </span>
        }
        <button
          class="account-button"
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="menu"
          aria-label="Account button"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="User Account"
            [fontIcon]="icons.account"
          ></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="toggleSettings('Settings')">
            Settings
          </button>
          @if (isAdmin) {
          <button (click)="loadUserManagement()" mat-menu-item>Admin</button>
          }
        </mat-menu>
      </mat-toolbar>
    </div>
  `,
  styles: [
    `
      .header {
        z-index: 1;
        position: relative;
        width: 100%;
        //box-shadow: var(--box-shadow);
      }

      .spacer {
        flex: 1 1 auto;
      }

      .mobile-styling {
        background-color: transparent;

        .mat-icon {
          color: white;
        }

      }
    `,
  ],
  standalone: true,
})
export class PageHeaderComponent implements OnInit {
  username: string = '';
  icons = {
    account: 'account_circle',
    theme: 'light_mode',
  };
  isAdmin: boolean = false;

  //mobile settings
  isMobile: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor(
    private pageHeaderSrv: PageHeaderService,
    private router: Router,
    public settingsDrawer: DrawerService,
    public profile: ProfileService,
    private breakPointService: BreakpointService
  ) {}

  //listens for a screen size change for displaying nav-bar or nav-bar-mobile
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
  }

  ngOnInit(): void {
    this.pageHeaderSrv.isAdmin$.subscribe((result) => {
      this.isAdmin = result;
    });

    //check screen size for nav-bar
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight,this.getScreenWidth);
  }

  toggleSettings(heading: string) {
    this.settingsDrawer.heading = heading;
    this.settingsDrawer.state.update((value: boolean) => (value = !value));
  }

  loadUserManagement() {
    this.router.navigate(['administration/users']);
  }
}
