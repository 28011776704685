import { Component } from '@angular/core';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIcon } from '@angular/material/icon';
import { CommonModule, NgFor } from '@angular/common';
import { NavItem } from '../../models/nav-item.model';
import { NavService } from '../../services/nav.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ClaytonLogoComponent } from "../../../clayton-logo/clayton-logo.component";
import { PageHeaderComponent } from 'src/app/shared/page-container';
import { NavItemComponent } from '../nav-item.component';

@Component({
  selector: 'nav-bar-mobile',
  standalone: true,
  imports: [MatToolbar,
    MatIcon,
    CommonModule,
    NgFor, 
    ClaytonLogoComponent,
    PageHeaderComponent,
    NavItemComponent
  ],
  templateUrl: './nav-bar-mobile.component.html',
  styleUrl: './nav-bar-mobile.component.scss'
})
export class NavBarMobileComponent {
  navItems: NavItem[] = [];

  constructor(private navService: NavService, private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) 
  {

    this.navService.navItems$.subscribe((i) => {
      this.navItems = i;
    });
  }

  ngOnInit() {
    //setup icons for mobile
    this.navItems.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.Url,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.IconUrl)
      )
    })
  }

}
