import { Injectable, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  state: WritableSignal<boolean> = signal(false);
  heading: string = '';
  constructor() {}

  openFilter() {
    this.state.set(true);
    this.heading = "Filter(s)";
  }
  closeFilter() {
    this.state.set(false);
  }
}
