export class Submission {
  Id?: number;
  FacilityNumber?: string;
  OrderNumber?: string;
  SerialNumber?: string;
  HomeCenterName?: string;
  SoldDate?: Date;
  CustomerName?: string;
  Version?: string;
  Status?: string;
  CertificateMailed?: boolean;
  SubmissionCreateDate?: Date;

  constructor(sub: Submission) {
    this.Id = sub.Id;
    this.FacilityNumber = sub.FacilityNumber;
    this.OrderNumber = sub.OrderNumber;
    this.SerialNumber = sub.SerialNumber;
    this.CertificateMailed = sub.CertificateMailed;
    this.CustomerName = sub.CustomerName;
    this.SoldDate = sub.SoldDate;
    this.Status = sub.Status;
    this.Version = sub.Version;
    this.HomeCenterName = sub.HomeCenterName;
    this.SubmissionCreateDate = sub.SubmissionCreateDate;
  }
}
