import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Subscription, map, startWith } from 'rxjs';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
} from 'src/app/shared/material';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';
import { FilterService } from 'src/app/shared/filtering';

@Component({
  selector: 'lib-multi-select-control',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
  ],
  templateUrl: './multi-select-control.component.html',
  styleUrl: './multi-select-control.component.scss',
})
export class MultiSelectControlComponent {
  @Input() control: DynamicFormControl;
  autoCompleteControl: FormControl;
  selectedValuesFormControl: FormControl;
  selectedValues: { value: any; label: string }[] = [];
  separatorKeysCodes = [COMMA, SPACE, ENTER];

  private _subscriptions: Subscription[] = [];

  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.autoCompleteControl = this.control.formControls.find((i) =>
      i.propertyBinding.includes('autocomplete')
    ).formControl;

    let sub = this.autoCompleteControl.valueChanges
      .pipe(
        startWith(null),
        map((value: string | null) => {
          if (value) {
            this.control.customOptions['filteredOptions'] =
              this.control.customOptions['selectOptions'].filter(
                (i: any) =>
                  !this.selectedValues?.includes(i) && i.label?.includes(value)
              );
          } else {
            this.control.customOptions['filteredOptions'] =
              this.control.customOptions['selectOptions'].filter(
                (i: any) => !this.selectedValues?.includes(i)
              );
          }
        })
      )
      .subscribe();
    this._subscriptions.push(sub);

    sub = this.filterService.activeFilterRemoved$.subscribe({
      next: (i) => {
        if (i.propertyBinding === this.control.propertyBinding) {
          this.remove({ value: i.propertyValue, label: i.value });
        }
      },
      error: (e) => console.log(e),
    });
    this._subscriptions.push(sub);

    this.selectedValuesFormControl = this.control.formControls.find(
      (i) => !i.propertyBinding.includes('autocomplete')
    ).formControl;

    //setting any selected values present on the control on init.
    if (this.selectedValuesFormControl.value) {
      // ensuring the value is an array and setting if not.
      if (typeof this.selectedValuesFormControl.value !== 'object') {
        this.selectedValuesFormControl.setValue([
          this.selectedValuesFormControl.value,
        ]);
      }

      this.selectedValuesFormControl.value.forEach((i: string) => {
        this.selectedValues = [
          ...this.selectedValues,
          this.control.customOptions['selectOptions'].find(
            (x: any) => x.label === i
          ),
        ];
      });
    }

    //listening for "clearing/reset events"
    this.selectedValuesFormControl.valueChanges.subscribe((i) => {
      if (i === null) {
        this.selectedValues = [];
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  remove(item: { value: any; label: string }) {
    let chipValue = this.selectedValues.find(
      (i) => i.value === item.value && i.label === item.label
    );
    let index = this.selectedValues.indexOf(chipValue);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);

      this.control.formControls
        .find((i) => i.propertyBinding.includes('autocomplete'))
        ?.formControl.setValue(this.selectedValues.map((i) => i.label));
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedValues.includes(event.option.value)) {
      this.selectedValues.push(event.option.value);
    }

    this.autoCompleteControl.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;
    const input = event.chipInput.inputElement;

    if ((value || '').trim()) {
      var option = this.control.customOptions.selectOptions.find((i: any) =>
        i.label?.includes(value)
      );
      this.selectedValues.push(option);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.autoCompleteControl.setValue(null);
  }
}
