import { RuleAction } from './rule-action.enum';

export interface FormRuleEffect {
  action?: RuleAction;
  value?: any;
  applyTo?: string;
  affectingType?: 'control' | 'group';
}

export class FormRule {
  condition?: string;
  appliedCondition?: string;
  effects?: FormRuleEffect[];

  constructor(condition?: string, effects?: FormRuleEffect[]) {
    this.condition = condition;
    this.effects = effects;
  }

  addEffect(effect: FormRuleEffect): void {
    this.effects = [...this.effects, effect];
  }
}
