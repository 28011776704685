<form [formGroup]="formGroup">
  <div class="body">
    @for (group of controlGroups; track group.label) {
    <section>
      <div class="filter-header-container">
        <div class="filter-header">{{ group.label }}</div>
        <div
          class="filter-reset"
          (click)="group.handlers.get('on_reset_click')()"
        >
          reset
        </div>
      </div>
      @for (control of group.getLocalControls(); track control) {
      <div class="filter-body-container">
        <div class="filter-label">
          <mat-label>{{ control.label }}:</mat-label>
        </div>
        <lib-dynamic-form-control
          [control]="control"
        ></lib-dynamic-form-control>
      </div>
      }
    </section>
    }
  </div>
  <div class="footer">
    <div class="buttons-container">
      <button mat-flat-button type="button" (click)="onResetAll()">
        Reset All
      </button>
      <button mat-flat-button type="button" color="accent" (click)="apply()">
        Apply
      </button>
    </div>
  </div>
</form>
