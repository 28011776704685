import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlBuilderService {
  private readonly DEV_ENV = 'dev';
  private readonly WSS_PREFIX = 'wss://events-api';
  private readonly GRAPH_PREFIX = 'https://graph-api';
  private readonly URL_BASE =
    window.location.href.indexOf('localhost') > -1
      ? 'dev.claytonnext.com'
      : window.location.host;

  generateWssUrl(): string {
    return environment.environment === this.DEV_ENV
      ? `${this.WSS_PREFIX}-${environment.subEnvironment}.${this.URL_BASE}`
      : `${this.WSS_PREFIX}.${this.URL_BASE}`;
  }

  generateGraphUrl(): string {
    return environment.environment === this.DEV_ENV
      ? `${this.GRAPH_PREFIX}-${environment.subEnvironment}.${this.URL_BASE}/graphql`
      : `${this.GRAPH_PREFIX}.${this.URL_BASE}/graphql`;
  }
}
