<div class="chip-container" *ngIf="service.activeFilters?.length > 0">
  <label>Applied Filters:</label>
  <mat-chip-set>
    @for (filter of (service.activeFilters); track filter) {
    <mat-chip class="chip chip-label">
      {{
        (filter.label ? filter.label : filter.propertyValue)
          | tableColumn
          | singularize
      }}: @if (filter.controlType === ControlType.DATERANGE) {
      <b>
        {{
          (filter.value ? filter.value.start : filter.propertyValue.start)
            | date
        }}
        -
        {{
          (filter.value ? filter.value.end : filter.propertyValue.end) | date
        }}
      </b>
      } @else if (filter.controlType === ControlType.DATE) {
      <b>
        {{ (filter.value ? filter.value : filter.propertyValue) | date }}
      </b>
      } @else {
      <b>
        {{ filter.value ? filter.value : filter.propertyValue }}
      </b>
      }
      <button
        matChipRemove
        aria-label="Remove item"
        (click)="service.removeActiveFilter(filter)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    }
  </mat-chip-set>
  <button
    mat-button
    color="warn"
    *ngIf="service.activeFilters?.length > 1"
    (click)="service.removeAllActiveFilters()"
  >
    <mat-icon>close</mat-icon>

    Clear
  </button>
</div>
