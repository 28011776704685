import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { Subscription, of, switchMap, tap } from 'rxjs';
import {
  PanelBodyComponent,
  PanelContainerComponent,
  PanelHeaderComponent,
} from 'src/app/shared/content-panels';
import { TableColumnPipe } from 'src/app/shared/pipes/table-column.pipe';
import { Submission } from '../models/submission.model';
import { SubmissionsService } from '../services/submissions.service';
import { DynamicFormControl, FormControlFactory } from 'src/app/shared/forms';
import { FilterService } from 'src/app/shared/filtering';
import {
  MatIconModule,
  MatPaginatorModule,
  MatTableDataSource,
  MatTableModule,
  MatTooltip,
  PageEvent,
} from 'src/app/shared/material';
import { MatMenuModule } from '@angular/material/menu';
import { DrawerService } from 'src/app/shared/services';
import { BreakpointService } from 'src/app/shared/services/breakpoint.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-submissions-list-view',
  standalone: true,
  imports: [
    CommonModule,
    TableColumnPipe,
    PanelBodyComponent,
    PanelContainerComponent,
    PanelHeaderComponent,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatTooltip
  ],
  templateUrl: './submissions-list-view.component.html',
  styleUrl: './submissions-list-view.component.scss',
})
export class SubmissionsListViewComponent {
  @Input() isDetailOpen = false;
  @Input() submission: Submission;
  @Output() selectedSubmission = new EventEmitter<Submission>();
  @Output() pageChange = new EventEmitter<PageEvent>();

  tableColumns: string[] = [
    'Id',
    'FacilityNumber',
    'OrderNumber',
    'SerialNumber',
    'HomeCenterName',
    'SoldDate',
    'CustomerName',
    'Version',
    'Status',
    'CertificateMailed',
    'SubmissionCreateDate'
  ];

  paginatorOptions = {
    paging: true,
    show: false,
    pageSizeOptions: [50, 100, 500],
    pageSize: 50,
    pageLength: 0,
    showFirstLastButtons: true,
  };

  dataSource = new MatTableDataSource<Submission>([]);
  isEditMode = false;

  //used to check against 90 days
  timestamp = new Date();
  dateFormat: string = 'MM/dd/yyyy';
  soldToolTip: string = "This Credit Submission has a sold date older than 90 days and may need your attention";

  //variables for screensize changes
  isSmallScreen: boolean = false;
  getScreenWidth: number;
  getScreenHeight: number;
  isBelow1150: boolean = false;
  isMobile: boolean = false;
  isBelow1050: boolean = false;

  private _subscriptions: Subscription[] = [];
  private _submissionCollection: Submission[] = [];
  private _filterControls: DynamicFormControl[];

  constructor(
    public drawerSvc: DrawerService,
    private filterSvc: FilterService,
    private submissionsSrv: SubmissionsService,
    private filterCtrlFactory: FormControlFactory,
    private breakPointService: BreakpointService
  ) {}

  //listens for a screen size change for displaying nav-bar or nav-bar-mobile
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isSmallScreen = this.breakPointService.smallScreenCheck(this.getScreenHeight,this.getScreenWidth);
    this.isBelow1150 = this.breakPointService.below1150Check(this.getScreenHeight, this.getScreenWidth);
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight, this.getScreenWidth);
  }

  ngOnInit() {
    //get the 90 day timestamp
    this.timestamp.setDate(this.timestamp.getDate() - 90);
    //check screen size for menu and styling changes
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isSmallScreen = this.breakPointService.smallScreenCheck(this.getScreenHeight,this.getScreenWidth);
    this.isBelow1150 = this.breakPointService.below1150Check(this.getScreenHeight, this.getScreenWidth);
    this.isMobile = this.breakPointService.mobileCheck(this.getScreenHeight, this.getScreenWidth);
  
    this._filterControls = this.buildControls();
    this.filterSvc.setFilterFormControls(this._filterControls);

    let sub = this.submissionsSrv.submissions$.subscribe(
      (i) => (
        (this._submissionCollection = i.Items), (this.dataSource.data = i.Items)
      )
    );
    this._subscriptions.push(sub);

    sub = this.submissionsSrv.facilities$
      .pipe(
        switchMap((result) => {
          return of(
            this.filterCtrlFactory.createMultiSelectBox(
              'Facilities',
              'Facilities',
              null,
              {
                placeholder: 'Select a Facility',
                selectOptions: result.flatMap((i) => ({
                  value: i.Id,
                  label: i.KeyChain.LegacyId,
                })),
              }
            )
          );
        })
      )
      .subscribe((filter) => {
        if (
          this._filterControls
            .map((i) => i.propertyBinding)
            .indexOf(filter.propertyBinding) === -1
        ) {
          this._filterControls.push(filter);
          this.filterSvc.setFilterFormControls(this._filterControls);
        }
      });
    this._subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  rowSelected(id: number | string) {
    let submission = this._submissionCollection.find((i) => i.Id === id);
    this.isDetailOpen = true;
    this.selectedSubmission.emit(submission);
  }

  /***
   * Checks SoldDate in table against 90 days 
   * returns true if SoldDate is older than 90 days
   * to add notifier icon in the column
   */
  isLessThanTargetDate(soldDate: Date) {
    const date1 = format(soldDate, this.dateFormat);
    const date2 = format(this.timestamp, this.dateFormat);
    return date1 < date2;
  }

  private buildControls = (): DynamicFormControl[] => [
    this.filterCtrlFactory.createTextBox('Order #', 'OrderNumber', null, {
      placeholder: 'Enter an Order Number',
    }),
    this.filterCtrlFactory.createTextBox('Serial #', 'SerialNumber', null, {
      placeholder: 'Enter a Serial Number',
    }),
    this.filterCtrlFactory.createTextBox(
      'Home Center',
      'HomeCenterNumber',
      null,
      { placeholder: 'Enter a Home Center' }
    ),
    this.filterCtrlFactory.createTextBox(
      'Customer',
      'CustomerName',
      null,
      { placeholder: 'Enter a Customer Name' }
    ),
    this.filterCtrlFactory.createDateRangePicker(
      'Sold Date',
      'SoldDate',
      null,
      { placeholder: 'Enter a Sold Date range' }
    ),
    this.filterCtrlFactory.createDateRangePicker(
      'Submission Create Date',
      'SubmissionCreateDate',
      null,
      { placeholder: 'Enter a Create Date range' }
    ),
    this.filterCtrlFactory.createMultiSelectBox('Status', 'Status', null, {
      placeholder: 'Select a Status',
      selectOptions: [
        {
          value: 1,
          label: 'Not Eligible',
        },
        {
          value: 2,
          label: 'Needs Attention',
        },
        {
          value: 3,
          label: 'In Progress',
        },
        {
          value: 4,
          label: 'Ready To Submit',
        },
        {
          value: 5,
          label: 'Submitted',
        },
        {
          value: 6,
          label: 'Manually Submitted',
        },
      ],
    }),
    this.filterCtrlFactory.createMultiSelectBox('Version', 'Version', null, {
      placeholder: 'Select a Version',
      selectOptions: [
        {
          value: 1,
          label: 'Not Selected',
        },
        {
          value: 2,
          label: '2',
        },
        {
          value: 3,
          label: '3',
        },
      ],
    }),
  ];
}
