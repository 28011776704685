import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavItemComponent } from './nav-item.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavItem } from '../../models';
import { NavService } from '../services/nav.service';
import { ClaytonLogoComponent } from "../../clayton-logo/clayton-logo.component";
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
  selector: 'nav-bar',
  imports: [NavItemComponent, CommonModule, MatSidenavModule, ClaytonLogoComponent, MatIcon, MatToolbar],
  template: `
    <mat-sidenav-container hasBackdrop="false" class="container">
      @if(!hideNavbar) {
        <mat-sidenav class="navbar" mode="side" position="start" opened>
        <!-- logo component -->
        <clayton-logo id="logo-container"></clayton-logo>
      
        @if (navItems && navItems.length > 0) { @for (item of navItems; track
        item) {
        <nav-item
          [name]="item.Name"
          [active]="item.Active"
          [iconUrl]="item.IconUrl"
          [url]="item.Url"
          [navbarHidden]="hideNavbar"
        ></nav-item>
        } }
        <div class="sidebar hide">
          <button style="background: transparent; width: 1px" (click)="closeNavbar()">
            <mat-icon>keyboard_double_arrow_left</mat-icon>
          </button>
        </div>
      </mat-sidenav>
      } @else {
        <mat-sidenav class="hidden-nav" mode="side" opened>
        <clayton-logo id="logo-container" style="margin-left: 0.5em"></clayton-logo>
        <ng-container>
            <div *ngFor="let item of navItems">
                <nav-item 
                [name]="item.Name"
                [active]="item.Active"
                [iconUrl]="item.IconUrl"
                [url]="item.Url"
                [navbarHidden]="hideNavbar"></nav-item>
            </div>
        </ng-container>
        <div class="sidebar open">
          <button style="background: transparent; width: 0.0625em" (click)="openNavbar()">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
        </mat-sidenav>
      }
      <mat-sidenav-content>
        <ng-content></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: [
    `
      .container {
        height: 100vh;
      }
      .navbar {
        //box-shadow: var(--box-shadow);
        width: 150px;
        background: var(--nav-menu-background);
        overflow-y: auto;

        #logo-container {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          width: 100%;
          height: 70px;
        }
      }

      .sidebar {
        float: right;
        position: absolute;
        bottom: 0;
      }

      .hide {
          left: 104px;
      }

      .open {
        left: 30px;
      }

      .hidden-nav {
        overflow-y: auto;
        width: 71px;
        background: var(--nav-menu-background);
      }
    `,
  ],
  standalone: true,
})
export class NavBarComponent {
  navItems: NavItem[] = [];

  hideNavbar: boolean = false;

  constructor(private navService: NavService) {
    this.navService.navItems$.subscribe((i) => {
      this.navItems = i;
    });
  }

  closeNavbar() {
    this.hideNavbar = true;
  }

  openNavbar() {
    this.hideNavbar = false;
  }

}
