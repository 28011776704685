import {
  animate,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const panelSlider = [
  trigger('toggle-detail-panel', [
    state(
      'closed',
      style({
        opacity: '0',
        width: '0%',
      })
    ),
    state(
      'open',
      style({
        width: '65%',
      })
    ),
    transition('open => closed', [animate('0.35s ease-in-out')]),
    transition('closed => open', [animate('0.35s ease-in-out')]),
  ]),
  trigger('toggle-main-panel', [
    state(
      'closed',
      style({
        width: '100%',
      })
    ),
    state(
      'open',
      style({
        width: '35%',
        display: 'block',
      })
    ),
    transition('open => closed', [animate('0.35s ease-in-out')]),
    transition('closed => open', [animate('0.35s ease-in-out')]),
  ]),
];
