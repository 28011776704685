import { Injectable, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GET_ALL_USERS,
  GET_USER_BY_ID,
  GET_USER_PREFERENCE_BY_AZURE_ID,
} from 'src/app/shared/graph-queries';
import { TokenClaimsService } from '../../../shared/services/token-claims.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Observable, Subject, map } from 'rxjs';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { localStorageConstants } from 'src/app/shared/services';
import { AssignedFacilityDataSource, Facility, PaginatedList } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public users$ = new Subject<PaginatedList<User>>();
  public facilities$ = new Subject<Facility[]>();

  constructor(
    private apolloClient: Apollo,
    private claimsService: TokenClaimsService,
    private themeService: ThemeService,
    private router: Router,
    private wsService: WebSocketService
  ) {}

  getUsers(filter?: any): void {
    this.apolloClient
      .query({
        query: GET_ALL_USERS,
        variables: {
          filter: JSON.stringify(filter),
        },
      })
      .subscribe((response: any) => {
        let users = response.data.GetAllUsers;
        let facilities = response.data.GetAllFacilities;
        this.users$.next(JSON.parse(JSON.stringify(users)));
        this.facilities$.next(JSON.parse(JSON.stringify(facilities)));
      });
  }

  getUserById(userId: number | string): Observable<User> {
    return this.apolloClient
      .query<any>({
        query: GET_USER_BY_ID,
        variables: {
          id: userId,
        },
      })
      .pipe(map((i) => JSON.parse(JSON.stringify(i.data.GetUser))));
  }

  loadUserPreferences() {
    this.apolloClient
      .query({
        query: GET_USER_PREFERENCE_BY_AZURE_ID,
        variables: {
          id: this.claimsService.tokenClaims$.oid,
        },
      })
      .subscribe((responce: any) => {
        let preference = responce.data.GetUserPreferenceByAzureId;
        localStorage.setItem(
          localStorageConstants.USER_PREFERENCE_KEY,
          preference.Value
        );
        this.redirectToDefaultUrl();
        this.themeService.loadUserTheme();
      });
  }

  redirectToDefaultUrl() {
    if (location.pathname == '/') {
      let pref = JSON.parse(
        localStorage.getItem(localStorageConstants.USER_PREFERENCE_KEY)
      );
      if (pref && pref.defaultUrl) {
        let redirectUrl = environment.production
          ? pref.defaultUrl.split('.com')
          : pref.defaultUrl.split('4200');
        this.router.navigate([redirectUrl[1]]);
      }
    }
  }

  saveFacilityAssignments(user: User) {
    this.wsService.send({
      metadata: {
        eventType: 'UPDATE',
        domain: 'UserFacilityAssignment',
      },
      data: {
        Id: user.Id,
        FacilityAssignments: user.FacilityAssignments.flatMap((i) => {
          return {
            UserId: user.Id,
            FacilityId: i.Facility.Id,
            IsDefault: i.IsDefault,
            LegacyId: i.Facility.KeyChain.LegacyId
          };
        }),
      },
    });
  }

  updateFacilityAssignments(
    user: User,
    facilitiesCollection: Facility[],
    assignedFacilities: AssignedFacilityDataSource[],
    updatedFac: AssignedFacilityDataSource
  ): void {
    let currentFac = user.FacilityAssignments.find(
      (i) => i.Facility.Id === updatedFac.Id
    );

    if (currentFac && !updatedFac.Assigned) {
      let index = user.FacilityAssignments.indexOf(currentFac);
      user.FacilityAssignments.splice(index, 1);

      assignedFacilities.forEach((i) => {
        if (i.Id === updatedFac.Id) i.IsDefault = false;
      });
    } else if (currentFac && updatedFac.Assigned) {
      user.FacilityAssignments.forEach((i) => {
        i.IsDefault = false;
      });

      let index = user.FacilityAssignments.indexOf(currentFac);
      currentFac.IsDefault = updatedFac.IsDefault;
      user.FacilityAssignments[index] = currentFac;
    } else {
      user.FacilityAssignments = [
        ...user.FacilityAssignments,
        {
          UserId: user.Id,
          Facility: facilitiesCollection.find((i) => i.Id === updatedFac.Id),
          FacilityId: updatedFac.Id,
          IsDefault: updatedFac.IsDefault,
        },
      ].sort(
        (a, b) => parseInt(a.Facility.KeyChain.LegacyId) - parseInt(b.Facility.KeyChain.LegacyId)
      );
    }
  }

  updateDefaultAssignedFacility(
    user: User,
    facilitiesCollection: Facility[],
    assignedFacilities: AssignedFacilityDataSource[],
    updatedFac: AssignedFacilityDataSource
  ) {
    let currentFac = user.FacilityAssignments.find(
      (i) => i.Facility.Id === updatedFac.Id
    );

    user.FacilityAssignments.forEach((i) => {
      i.IsDefault = false;
    });

    assignedFacilities.forEach((i) => {
      if (i.Id !== updatedFac.Id) i.IsDefault = false;
      else i.Assigned = true;
    });

    if (!currentFac) {
      user.FacilityAssignments = [
        ...user.FacilityAssignments,
        {
          UserId: user.Id,
          Facility: facilitiesCollection.find((i) => i.Id === updatedFac.Id),
          FacilityId: updatedFac.Id,
          IsDefault: updatedFac.IsDefault,
        },
      ].sort(
        (a, b) => parseInt(a.Facility.KeyChain.LegacyId) - parseInt(b.Facility.KeyChain.LegacyId)
      );
    } else {
      let index = user.FacilityAssignments.indexOf(currentFac);
      currentFac.IsDefault = updatedFac.IsDefault;
      user.FacilityAssignments[index] = currentFac;
    }
  }

  generateAssignedFacilitiesDataSource(
    user: User,
    facilitiesCollection: Facility[]
  ): AssignedFacilityDataSource[] {
    return facilitiesCollection
      .map((i) => {
        let fac = user.FacilityAssignments.find((x) => x.Facility.Id === i.Id);
        let isAssigned = fac ? true : false;
        let isDefault = fac ? fac.IsDefault : false;
        return new AssignedFacilityDataSource().convertFromFacility(
          i,
          isAssigned,
          isDefault
        );
      });
  }
}
