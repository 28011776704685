import gql from 'graphql-tag';

export const GET_ALL_SUBMISSIONS = gql`
  query GetAllSubmissions($filter: String, $id: String) {
    GetLoggedInUser(Id: $id) {
      FirstName
      LastName
      FacilityAssignments {
        Facility {
          Id
          Name
          KeyChain {
            LegacyId
            OracleId
            SalesforceId
          }
        }
      }
    }
    GetAllEnergyCreditSubmissions(filter: $filter) {
      Items {
        Id
        OrderNumber
        SerialNumber
        FacilityNumber
        CustomerName
        HomeCenterName
        CertificateMailed
        Status
        Version
        SoldDate
        SubmissionCreateDate
      }
      HasNextPage
      HasPreviousPage
      PageNumber
      PageSize
      TotalCount
      TotalPages
      SortDirection
    }
  }
`;
